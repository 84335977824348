.App {
  text-align: center;
}

.page {
  margin: 15px;
}

body {
  padding: 0px;
  margin: 0px;
  font-family: Roboto;
}

.nav-link {
  color: white;
  text-decoration: none;
  padding-right: 10px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  letter-spacing: 0.02857em;
  font-size: 0.875rem;
  text-transform: uppercase;
}

.flex-one {
  flex: 1;
}

.error-message {
  color: red;
}

.inputDiv {
  background: #eee;
  text-align: center;
  height: 400px;
  width: 320px;
  padding: 30px;
}

progress {
  width: 20em;
}

.img-upload {
  margin-top: 15px;
  max-width: 90%;
  max-height: 90%;
  width: auto;
  height: auto;
}
